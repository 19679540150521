//Configuración específica de prod
import {merge} from 'lodash';
import configBase from './configBase';

const config = {
	"environment": "prod",
	"apis": {
		"authApi": {
			"URL": "https://api.authcont.misiones.gob.ar"
		},
		"presupuestoApi": {
			"URL": "https://api.presupuesto.misionescontaduria.com/v1",
			"URL_V2": "https://api.presupuesto.misionescontaduria.com/v2",
			"VERSION": "https://api.presupuesto.misionescontaduria.com"
		},
		"coreApi": {
			"URL": "https://coreapi.misiones.gob.ar"
 		},
 		"fundsRequestsApi": {
 			"URL": "https://apifondos.misiones.gob.ar"
 		}
	}
};

export default merge(configBase, config);